/* eslint-disable max-len */
// External Dependencies
import Container from '@mui/material/Container';
import React from 'react';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import HeadingWithAnchor from '../components/shared/HeadingWithAnchor';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const termsOfServiceMailToLink = `mailto:info@presto-assistant.com?subject=${encodeURIComponent('From the Presto Terms of Service Page')}`;

// Component Definition
const TermsOfService = () => (
  <Layout>
    <Meta title="Terms of Service" />

    <HeaderGeneric title="Terms of Service" />

    <div id="main">
      <section id="content" className="main">
        <Container maxWidth="md">
          <section className="flex-space-between">
            <h2>Presto Assistant LLC Terms of Service</h2>

            <h5>Revised 11-19-2022</h5>
          </section>

          <section>
            <HeadingWithAnchor headingText="Overview" />

            <p>
              This website is operated by Presto Assistant LLC. Throughout the site, the terms &quot;Presto&quot;, &quot;Presto Assistant&quot; &quot;we&quot;, &quot;us&quot;, and &quot;our&quot; refer to Presto Assistant LLC. Presto Assistant LLC offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Terms" />

            <p>
              By accessing the website at <a href="https://www.presto-assistant.com">https://www.presto-assistant.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="General Conditions" />

            <p>
              We reserve the right to refuse service to anyone for any reason at any time.
            </p>

            <p>
              You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Use License" />

            <ol type="a">
              <li>Permission is granted to temporarily download one copy of the
                materials (information or software) on Presto Assistant&apos;s
                website for personal, non-commercial transitory viewing only.
                This is the grant of a license, not a transfer of title,
                and under this license you may not:
                <ol type="i">
                  <li>modify or copy the materials;</li>
                  <li>use the materials for any commercial purpose, or
                    for any public display (commercial or non-commercial);
                  </li>
                  <li>attempt to decompile or reverse engineer any software
                    contained on Presto Assistant&apos;s website;
                  </li>
                  <li>remove any copyright or other proprietary notations from the materials; or</li>
                  <li>transfer the materials to another person or
                    &quot;mirror&quot; the materials on any other server.
                  </li>
                </ol>
              </li>
              <li>This license shall automatically terminate if you violate
                any of these restrictions and may be terminated by Presto Assistant at any time. Upon terminating your viewing of these materials
                or upon the termination of this license, you must destroy any
                downloaded materials in your possession whether in electronic
                or printed format.
              </li>
            </ol>
          </section>

          <section>
            <HeadingWithAnchor headingText="Disclaimer" />

            <ol type="a">
              <li>The materials on Presto Assistant&apos;s website are
                provided on an &apos;as is&apos; basis. Presto makes no warranties,
                expressed or implied, and hereby
                disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other
                violation of rights.
              </li>
              <li>Further, Presto does not warrant or make
                any representations concerning the accuracy, likely results,
                or reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites
                linked to this site.
              </li>
            </ol>
          </section>

          <section>
            <HeadingWithAnchor headingText="Limitations" />

            <p>
              In no event shall Presto Assistant or its suppliers be
              liable for any damages (including, without limitation, damages
              for loss of data or profit, or due to business interruption)
              arising out of the use or inability to use the materials on
              Presto&apos;s website, even if Presto or a Presto authorized representative has
              been notified orally or in writing of the possibility of such
              damage. Because some jurisdictions do not allow limitations on
              implied warranties, or limitations of liability for consequential
              or incidental damages, these limitations may not apply to you.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Accuracy of Materials" />

            <p>
              The materials appearing on Presto Assistant&apos;s website
              could include technical, typographical, or photographic errors.
              Presto Assistant does not warrant that any of the materials
              on its website are accurate, complete or current. Presto Assistant
              may make changes to the materials contained on its website
              at any time without notice. However Presto does
              not make any commitment to update the materials.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Links" />

            <p>
              Presto Assistant has not reviewed all of the sites linked
              to its website and is not responsible for the contents of any
              such linked site. The inclusion of any link does not imply
              endorsement by Presto of the site. Use of any
              such linked website is at the user&apos;s own risk.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Modifications" />

            <p>
              Presto Assistant may revise these terms of service for
              its website at any time without notice. By using this website
              you are agreeing to be bound by the then current version of
              these terms of service.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Governing Law" />

            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of Texas and you irrevocably submit
              to the exclusive jurisdiction of the courts in that State or location.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="User Provided Content" />

            <p>
              We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
            </p>

            <p>
              You agree that your content and organization&apos;s data will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your content will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of your data. You are solely responsible for any content you provide and its accuracy. We take no responsibility and assume no liability for any content posted by you or any third-party.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Online Payments" />

            <p>
              By visiting our site and/or making online payments through us, you engage in our &quot;Service&quot; and agree to be bound by the above terms and conditions (&quot;Terms of Service&quot;, &quot;Terms&quot;), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
            </p>

            <p>
              Presto Assistant uses Stripe Connect, a service provided by <a href="https://stripe.com" rel="noreferrer noopener" target="_blank">Stripe, Inc</a> (&quot; Stripe&quot;). By making payments through Stripe, you acknowledge that you agree to to the <a href="https://stripe.com/us/connect-account/legal" rel="noreferrer noopener" target="_blank">Stripe Connected Account Agreement</a> and the <a href="https://stripe.com/privacy" rel="noreferrer noopener" target="_blank">Stripe Privacy Policy</a>.
            </p>

            <p>
              Any fees added to online payments are calculated based on the fee for using Stripe Connect. Presto Assistant does not store your credit card information. By making online payments, you agree that you are the authorized to make payments via the payment method you provide. Transactions and any associated fees are presented and received in U.S. Dollars. Processing fees are non-refundable. If a refund is issued, only the subtotal will be refunded.
            </p>

            <p>
              In the event a member of an organization disputes a payment and receives a refund from Presto Assistant, the organization is responsible for reimbursing Presto Assistant the disputed amount, application fees, and chargeback fees. If the organization does not pay the disputed amount within 30 days of the dispute settlement, the organization will be locked from the Presto Assistant platform.
            </p>
          </section>

          <section>
            <HeadingWithAnchor headingText="Contact Information" />

            <p>
              Questions about the Terms of Service should be sent to us at <a href={termsOfServiceMailToLink}>info@presto-assistant.com</a>.
            </p>
          </section>
        </Container>
      </section>
    </div>
  </Layout>
);

export default TermsOfService;
